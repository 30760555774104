import React from 'react'
import styled from 'styled-components'

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean
}

const Bullet = styled.button<Props>`
  width: 10px;
  min-width: 10px;
  max-width: 10px;
  height: 10px;
  min-height: 10px;
  max-height: 10px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.white)};
  cursor: pointer;
  margin: 0 10px;
  transition: 0.15s;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

export default Bullet
