import React from 'react'
import styled from 'styled-components'

type Props = React.HTMLAttributes<HTMLHeadingElement> & {
  text: string
  color?: string
}

const Title = styled.h2<{ textColor?: string }>`
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  position: relative;
  margin: 25px 0 35px;
  text-transform: uppercase;
  color: ${({ textColor }) => textColor ?? 'inherit'};

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 50px);
    bottom: -8px;
    width: 100px;
    height: 6px;
    background: ${({ textColor }) => textColor ?? 'currentColor'};
  }
`

const SectionTitle = ({ text, color, className, ...restProps }: Props) => (
  <Title {...restProps} textColor={color} className={className}>
    {text}
  </Title>
)

export default SectionTitle
